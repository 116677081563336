import { fetchClientInfo, type TFetchClientInfoArgs } from '@/helpers/api/resources';
import sharedCall from '@/helpers/sharedCall';

import { defaultComparator } from '../comparators';
import { FetchablePartName, type TFetchableArgs, type TFetchablePart, type TFetchableRawArgs } from '../types';

type TArgs = Omit<TFetchClientInfoArgs, 'onResponseHeaders'>;

const clientInfo: TFetchablePart<FetchablePartName.CLIENT_INFO, TArgs> = {
  dependOn: [FetchablePartName.CLIENT_IP, FetchablePartName.GEO_LOCATION],
  fetcher: sharedCall(
    ({ clientIp, countryCode, countryRegionCode, onResponseHeaders }: TFetchableArgs<TArgs>) => {
      return fetchClientInfo({ clientIp, countryCode, countryRegionCode, onResponseHeaders });
    },
    { compareFn: defaultComparator },
  ),
  name: FetchablePartName.CLIENT_INFO,
  prepareArgs: ({ dependencies }: TFetchableRawArgs): TArgs => {
    const { clientIp, geoLocation } = dependencies || {};
    const { countryCode, stateCode: countryRegionCode } = geoLocation || {};
    return { clientIp, countryCode, countryRegionCode };
  },
};

export default clientInfo;
