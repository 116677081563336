import { ApolloError } from '@apollo/client';
import omit from 'lodash/omit';

import type { TUIResourceImage } from '@/ducks/common/resources';
import type { MnvvContent, MnvvData } from '@/ducks/mnvv/types';
import type { TStorePromoBanners } from '@/features/promoBanners/slice';
import type { TStrikethroughData } from '@/features/strikethrough/types';
import type { TAgencyAgentResponse } from '@/infra/types/fmlink/agent';
import type { Voyage } from '@/infra/types/voyageInfo/voyage';
import type { TCampaign, TLegalContent, TOptional } from '@/types';

import { CURRENCY_CODE_COOKIE, DEFAULT_COUNTRY_CODE } from '@/constants/settings';
import { AgentDataActionType } from '@/ducks/fm/types';
import { buildAgentDetails } from '@/ducks/ValidateAgentAgencyDetails';
import { fetchImage, fetchLegalContents, fetchVVCampaigns } from '@/features/api/endpoints/common';
import { fetchAddons } from '@/features/api/endpoints/voyage';
import { type TFetchOptions } from '@/features/api/simpleFetch';
import { apolloErrorToJson, extractQueryResultError } from '@/features/apollo/errorsToJson';
import fetchWithTTLSupport, { type TFetchWithTTL } from '@/features/apollo/fetchWithTTLSupport';
import makeApolloClient from '@/features/apollo/makeApolloClient';
import {
  fetchQuickSearchItems,
  QUICK_SEARCH_ITEMS_RESOURCES,
  type TFetchQuickSearchItemsArgs,
} from '@/features/promo/QuickSearchCards/model/actions';
import { type QuickSearchItemsData } from '@/features/promo/QuickSearchCards/model/types';
import { createQuickSearchItemsRecord } from '@/features/promo/QuickSearchCards/model/utils';
import { promoBannerPolicies } from '@/features/promoBanners/apolloPolicies';
import { allFitters } from '@/features/promoBanners/fitters';
import { strikethroughPolicies } from '@/features/strikethrough/apolloPolicies';
import { strikethroughDataNull, type TStoreStrikethrough } from '@/features/strikethrough/slice';
import {
  FETCH_AGENCY_CURRENCY_DETAILS,
  FETCH_CLIENT_INFO,
  FETCH_PROMO_BANNERS_V2,
  type GQLClientInfoLookup,
  type GQLClientInfoResponse,
  type GQLPromoBannersApiAnswer,
} from '@/helpers/api/graphql/queries';
import { FETCH_STRIKETHROUGH_DATA, type GQLStrikethroughApiAnswer } from '@/helpers/api/graphql/queries/strikethrough';
import { FETCH_VOYAGES, type FetchVoyagesResponse } from '@/helpers/api/graphql/queries/voyages';
import { type TAuthHeaders, TokenType, withAuthHeader } from '@/helpers/api/tokens/helpers/withToken';
import tryParseJson from '@/helpers/tryParseJson';
import { log } from '@/helpers/util/logger';
import { type TWithResponseHeaders } from '@/infra/types/api';
import { type TImage, type TUrlParams } from '@/infra/types/common';
import { type TAgencyDetails, type TAgentDetails } from '@/infra/types/fmlink/agent';
import { type TCookies } from '@/store/fetchableParts/types';

const clientInfoCache = new Map<string, GQLClientInfoLookup>();
const apolloClient = makeApolloClient();

export type TFetchArgs = RequestInit & TWithResponseHeaders;

export type TCampaignAndLegal = {
  campaign: TCampaign;
  legalContent: TLegalContent;
};

export const fetchCampaignAndLegal = async (
  areCelebrationsEnabled: boolean,
  fetchArgs: TFetchOptions,
): Promise<TCampaignAndLegal> => {
  const externalId = areCelebrationsEnabled ? 'CEL' : 'MGM';
  const campaignsResponse = await fetchVVCampaigns({ ...fetchArgs, params: { ...fetchArgs.params, externalId } });
  const campaign = campaignsResponse.results[0]!;

  const name = campaign.name;
  const legalContentResponse = await fetchLegalContents({
    ...omit(fetchArgs, 'onResponseHeader'),
    params: { ...fetchArgs.params, name },
  });
  const legalContent = legalContentResponse.results[0]!;

  return { campaign, legalContent };
};

export const QUICK_SEARCH_RESOURCES = ['Promotion.headerBackground', ...QUICK_SEARCH_ITEMS_RESOURCES] as const;

export type TFetchQuickSearchArgs = Omit<TFetchQuickSearchItemsArgs, 'apolloClient' | 'resources'> & {
  resources: TFetchQuickSearchItemsArgs['resources'] & { 'Promotion.headerBackground': TImage };
};

export const fetchQuickSearchItemsData = async (args: TFetchQuickSearchArgs): Promise<QuickSearchItemsData> => {
  const { currencyCode = 'USD', onResponseHeaders, resources, sailors = 2 } = args || {};
  // TODO: replace naming from CMS side
  const backgroundImageSrc = (resources?.['Promotion.headerBackground'] as TUIResourceImage)?.src;
  const [headlineBackground, items] = await Promise.all([
    backgroundImageSrc ? fetchImage(backgroundImageSrc).catch(() => undefined) : Promise.resolve(undefined),
    fetchQuickSearchItems({ apolloClient, currencyCode, onResponseHeaders, resources, sailors }),
  ]);
  return { headlineBackground, items: createQuickSearchItemsRecord(items, currencyCode, sailors) };
};

export type TFetchClientInfoArgs = TWithResponseHeaders & {
  clientIp?: string;
  countryCode?: string;
  countryRegionCode?: string;
};

export const fetchClientInfo = withAuthHeader(
  TokenType.guest,
  async (
    authHeaders: TAuthHeaders,
    { clientIp, countryCode, countryRegionCode, onResponseHeaders }: TFetchClientInfoArgs,
  ): Promise<TOptional<GQLClientInfoLookup>> => {
    const key = `${countryCode} ${countryRegionCode}`;
    if (!clientInfoCache.has(key)) {
      try {
        const headers = {
          ...authHeaders,
          clientIp,
        };
        const data = (
          await apolloClient.query({
            context: { headers, onResponseHeaders },
            fetchPolicy: 'no-cache',
            query: FETCH_CLIENT_INFO,
            variables: {
              clientRegion: countryCode || DEFAULT_COUNTRY_CODE,
              clientSubRegion: countryRegionCode,
            },
          })
        ).data as GQLClientInfoResponse;
        clientInfoCache.set(key, data?.lookup);
      } catch (e) {
        if (e instanceof ApolloError) {
          log(apolloErrorToJson(e));
          return;
        }

        log(e);
        return;
      }
    }

    return clientInfoCache.get(key);
  },
);

export type TFetchAgentDetailsArgs = TWithResponseHeaders & {
  cookies: TCookies;
  defaultCurrencyCode: TOptional<string>;
  urlParams: TUrlParams;
};

export type TFetchAgentDetails = {
  action: string;
  response: TAgencyAgentResponse;
};

export const fetchAgentDetails = withAuthHeader(
  TokenType.guest,
  async (
    authHeaders: TAuthHeaders,
    { cookies, defaultCurrencyCode, onResponseHeaders, urlParams }: TFetchAgentDetailsArgs,
  ): Promise<TOptional<TFetchAgentDetails>> => {
    try {
      const action = validateFMFlow(urlParams, cookies);
      if (action === AgentDataActionType.remove) {
        return { action, response: {} as TAgencyAgentResponse };
      }
      if (action === AgentDataActionType.exist) {
        // update the redux store with the cookie data
        const { agencyCurrencyCode, agencyDetails, agentDetails } = cookies || {};
        const response = {
          agencyCurrencyCode,
          agencyDetails: tryParseJson<TAgencyDetails>(agencyDetails!),
          agentDetails: tryParseJson<TAgentDetails>(agentDetails!),
        } as TAgencyAgentResponse;
        return { action, response };
      }
      const { agencyId, agentId, bookingChannel, currencyCode } = urlParams || {};
      const currency = currencyCode || cookies?.[CURRENCY_CODE_COOKIE] || defaultCurrencyCode || 'USD';
      const data = (
        await apolloClient.query({
          context: { headers: authHeaders, onResponseHeaders },
          query: FETCH_AGENCY_CURRENCY_DETAILS,
          variables: { agencyId, agentId, bookingChannel, currency, source: 'BOOK' },
        })
      ).data;
      const details = data?.fetchAgencyCurrencyDetails;

      if (details.valid === false) {
        return { action: AgentDataActionType.remove, response: {} as TAgencyAgentResponse };
      }

      return { action: AgentDataActionType.set, response: buildAgentDetails(details) };
    } catch (e) {
      log(e);
      return;
    }
  },
);

export type TCookieAgentDetails = TAgentDetails & {
  expiresAt: number;
};

const validateFMFlow = (urlParams: TUrlParams, cookies: TCookies) => {
  const { agencyId, agentId, channelID, reservationNumber } = urlParams || {};
  const { agencyCurrencyCode, agencyDetails, agentDetails } = cookies || {};
  try {
    const isMNVV = !!(channelID && reservationNumber);
    if (!isMNVV) {
      const { agentId: cookieAgentId, expiresAt } =
        (agentDetails ? tryParseJson<TCookieAgentDetails>(agentDetails) : undefined) || ({} as TCookieAgentDetails);
      if (expiresAt && Date.now() > expiresAt) return AgentDataActionType.remove;

      // In Fm Flow page refresh avoid making GQL call
      const { agencyId: cookieAgencyId } =
        (agencyDetails ? tryParseJson<TAgencyDetails>(agencyDetails) : undefined) || ({} as TAgencyDetails);
      const isAgreed = (cookie: unknown, param: unknown) => cookie && (!param || (param && cookie === param));
      if (agencyCurrencyCode && isAgreed(cookieAgencyId, agencyId) && isAgreed(cookieAgentId, agentId)) {
        return AgentDataActionType.exist;
      }

      if (agentId || agencyId) return AgentDataActionType.set;
    }
  } catch (err) {
    log(err);
  }

  return AgentDataActionType.remove;
};

export type TFetchPromoBannersArgs = (TFetchWithTTL & TWithResponseHeaders) & { countryCode?: string };

export const fetchPromoBanners = fetchWithTTLSupport(
  async ({
    apolloClient,
    countryCode,
    fetchOptions,
    fetchPolicy,
    onResponseHeaders,
  }: TFetchPromoBannersArgs): Promise<TStorePromoBanners> => {
    try {
      const variables = countryCode ? { countryCode } : {};

      const result = await apolloClient.query<GQLPromoBannersApiAnswer>({
        context: { fetchOptions, onResponseHeaders },
        fetchPolicy: fetchPolicy || 'cache-first',
        query: FETCH_PROMO_BANNERS_V2,
        variables,
      });
      const error = extractQueryResultError(result);
      if (error) {
        log(error);
        return { data: [], error };
      }
      return { data: allFitters(result?.data?.promoBannersV2) || [], error: undefined };
    } catch (exc) {
      const error = apolloErrorToJson(exc as ApolloError);
      log(error);
      return { data: [], error };
    }
  },
  makeApolloClient({ cacheOptions: { typePolicies: promoBannerPolicies } }),
);

export type TFetchStrikethroughArgs = TFetchWithTTL & TWithResponseHeaders;

export const fetchStrikethrough = fetchWithTTLSupport(
  async ({
    apolloClient,
    fetchOptions,
    fetchPolicy,
    onResponseHeaders,
  }: TFetchStrikethroughArgs): Promise<TStoreStrikethrough> => {
    try {
      const result = await apolloClient.query<GQLStrikethroughApiAnswer>({
        context: { fetchOptions, onResponseHeaders },
        fetchPolicy: fetchPolicy || 'cache-first',
        query: FETCH_STRIKETHROUGH_DATA,
      });
      const error = extractQueryResultError(result);
      if (error) {
        log(error);
        return {
          data: strikethroughDataNull,
          error,
        };
      }
      const strikethroughData = result?.data?.lookup?.strikethroughData[0] as unknown as TStrikethroughData;
      return {
        data: strikethroughData ?? strikethroughDataNull,
        error: undefined,
      };
    } catch (exc) {
      const error = apolloErrorToJson(exc as ApolloError);
      log(error);
      return {
        data: strikethroughDataNull,
        error,
      };
    }
  },
  makeApolloClient({ cacheOptions: { typePolicies: strikethroughPolicies } }),
);

export type TFetchMnvvArgs = {
  reservationNumber?: string;
  userToken?: string;
};

const mnvvApollo = makeApolloClient({ isOmitBuiltinAuth: true });

export const fetchMnvvData = async ({
  onResponseHeaders,
  reservationNumber,
  userToken,
  ...restOptions
}: TFetchMnvvArgs & TFetchArgs): Promise<Omit<MnvvData, 'channelID'>> => {
  let voyage: undefined | Voyage;
  try {
    const { data } = await mnvvApollo.query<FetchVoyagesResponse>({
      context: { headers: { authorization: `bearer ${userToken}` }, onResponseHeaders },
      fetchPolicy: 'cache-first',
      query: FETCH_VOYAGES,
      variables: { reservationNumbers: [reservationNumber] },
    });
    voyage = data.myVoyagesV2?.[0];
  } catch (e) {
    log(apolloErrorToJson(e as ApolloError));
  }

  let content: MnvvContent | undefined;
  if (voyage) {
    try {
      content = (await fetchAddons({ ...restOptions, params: { externalId: voyage.voyageId } }))?.results?.[0];
    } catch (e) {
      log(e);
    }
  }

  return { content, voyage };
};
