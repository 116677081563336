import { env } from '@/environment';
import { fetchPromoBanners, type TFetchPromoBannersArgs } from '@/helpers/api/resources';
import sharedCall from '@/helpers/sharedCall';

import { defaultComparator } from '../comparators';
import { FetchablePartName, type TFetchableArgs, type TFetchablePart, type TFetchableRawArgs } from '../types';

type TArgs = Pick<TFetchPromoBannersArgs, 'countryCode'>;

const promoBanners: TFetchablePart<FetchablePartName.PROMO_BANNERS, TArgs> = {
  dependOn: [FetchablePartName.GEO_LOCATION],
  fetcher: sharedCall(
    ({ countryCode, onResponseHeaders }: TFetchableArgs<TArgs>) => {
      const ttl = +env.NEXT_CACHE_TIME;
      return fetchPromoBanners({ countryCode, onResponseHeaders, ttl });
    },
    { compareFn: defaultComparator },
  ),
  name: FetchablePartName.PROMO_BANNERS,
  prepareArgs: ({ dependencies }: TFetchableRawArgs): TArgs => {
    const { countryCode } = dependencies?.geoLocation || {};
    return { countryCode };
  },
};

export default promoBanners;
