import type { AppRouterInstance } from 'next/dist/shared/lib/app-router-context.shared-runtime';

import { useEffect, useRef, useTransition } from 'react';

import { useRouter } from '@bprogress/next';

/**
 * router with async fns (replace, ...),
 * they will be resolved, when url changes finished
 */
export const useAsyncRouter = () => {
  const [isPending, setIsPending] = useTransition();
  const router = useRouter();
  const resolver = useRef<((value?: unknown) => void) | null>(null);

  const routerReplace = (
    href: string,
    resolve: (value?: unknown) => void,
    options?: Parameters<AppRouterInstance['replace']>[1],
  ) => {
    setIsPending(() => {
      router.replace(href, options);
      resolver.current = resolve;
    });
  };

  const replace = (href: string, options?: Parameters<AppRouterInstance['replace']>[1]) => {
    releaseWaiting();
    return new Promise((resolve) => routerReplace(href, resolve, options));
  };

  useEffect(() => {
    if (isPending || !resolver.current) {
      return;
    }

    releaseWaiting();
  }, [isPending]);

  const releaseWaiting = () => {
    // handle case when multiple replace calls happened
    if (!resolver.current) {
      return;
    }

    resolver.current?.();
    resolver.current = null;
  };

  return {
    ...router,
    replace,
  };
};
