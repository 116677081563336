import { env } from '@/environment';
import { fetchTravelInsurance } from '@/features/api/endpoints/summary';
import sharedCall from '@/helpers/sharedCall';

import { emptyComparator } from '../comparators';
import { FetchablePartName, type TFetchableArgs, type TFetchablePart } from '../types';

const travelInsurance: TFetchablePart<FetchablePartName.TRAVEL_INSURANCE> = {
  // dependOn: [],
  fetcher: sharedCall(
    ({ onResponseHeaders }: TFetchableArgs) => {
      const options = { next: { revalidate: +env.NEXT_CACHE_TIME }, onResponseHeaders };
      return fetchTravelInsurance(options);
    },
    { compareFn: emptyComparator },
  ),
  name: FetchablePartName.TRAVEL_INSURANCE,
};

export default travelInsurance;
