import { isProdEnv } from '@/helpers/util/env';

export const GEO_LOCATION_REGION_HEADER = 'X-Client-Geo-Location';
export const GEO_LOCATION_SUBREGION_HEADER = 'X-Client-Geo-Location-SubRegion';

export const extractClientIp = (headers: Headers) =>
  (!isProdEnv() && headers.get('x-dev-forwarded-for')) ||
  headers.get('x-forwarded-for')?.split(/\s*,\s*/)[0] ||
  undefined;

export const extractCountryCode = (headers: Headers) => headers.get(GEO_LOCATION_REGION_HEADER) || undefined;

export const extractSubRegionCode = (headers: Headers) => headers.get(GEO_LOCATION_SUBREGION_HEADER) || undefined;

export const extractSearchParams = (headers: Headers) => new URLSearchParams(headers?.get('x-search') || '');
