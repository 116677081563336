import { findBestPriceSailingForPackage } from '@/ducks/voyageInfo/sailing/selectors';
import { env } from '@/environment';
import { fetchSailings, type TFetchSailingsUrlParams } from '@/features/api/endpoints/voyage';
import sharedCall from '@/helpers/sharedCall';

import { defaultComparator } from '../comparators';
import { FetchablePartName, type TFetchableArgs, type TFetchablePart, type TFetchableRawArgs } from '../types';

const sailings: TFetchablePart<FetchablePartName.SAILINGS, TFetchSailingsUrlParams> = {
  dependOn: (args) => {
    const { urlParams } = args || {};
    if (!urlParams?.voyageId) return [FetchablePartName.VOYAGES];
  },
  fetcher: sharedCall(
    ({ onResponseHeaders, packageCode, voyageId }: TFetchableArgs<TFetchSailingsUrlParams>) => {
      const params = { packageCode, voyageId };
      const options = { next: { revalidate: +env.NEXT_CACHE_TIME }, onResponseHeaders, params };
      return fetchSailings(options);
    },
    { compareFn: defaultComparator },
  ),
  name: FetchablePartName.SAILINGS,
  prepareArgs: ({ dependencies, urlParams }: TFetchableRawArgs): TFetchSailingsUrlParams => {
    const { packageCode, voyageId: voyageIdParam } = (urlParams || {}) as Record<string, string>;
    if (!packageCode) throw new TypeError('A non-empty "packageCode" parameter is required');

    let voyageId = voyageIdParam;
    if (!voyageId) {
      const { packages } = dependencies?.voyages?.response || {};
      voyageId = findBestPriceSailingForPackage(packages!, packageCode!)?.id;
    }
    return { packageCode, voyageId: voyageId! };
  },
  urlParamNames: ['packageCode', 'voyageId'],
};

export default sailings;
