export type TNullable<T> = null | T;
export type TNullableRecord<T> = {
  [P in keyof T]: TNullable<T[P]>;
};

export const CURRENCY_CODES = ['AUD', 'CAD', 'EUR', 'GBP', 'NZD', 'USD'] as const;
export type TCurrencyCode = (typeof CURRENCY_CODES)[number];

// TODO: It is advisable to clarify with format "dddd-dd-dd"
export type TISODate = string;
// TODO: It is advisable to clarify with format "dddd-dd-ddTdd:dd:dd.dddZ"
export type TISODateTime = string;
export type TFormattedDate = string;
// TODO: It is advisable to clarify with format "dd:dd am/pm"
export type TFormattedTime = string;
export type TFormattedDateTime = string;

export type TDateRange = {
  endDate: TISODate;
  startDate: TISODate;
};

export type TRichText = `${string}<${string}>${string}`;

export type TUrlParams = Record<string, string | string[]>;

export type TCodifiedName = {
  code: string;
  name: string;
};

export type TReceived<D, E = unknown> = {
  data: D;
  error?: E;
};

export type TImage = {
  alt: string;
  src: string;
};

export type TMediaSrcSet = {
  [key: `${number}`]: string;
};

export type TMediaSet = {
  alt: string;
  srcSet: TMediaSrcSet;
};

export type TLink = {
  label: string;
  url: string;
};

export type Ship = TCodifiedName;

export type BookingChannels = 'FMLINK' | 'OPENTRAVEL';
