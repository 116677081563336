import type { ApolloCache, ApolloClient, FetchPolicy, NormalizedCacheObject, StoreObject } from '@apollo/client';

import type { TStoreLoadablePartition } from '@/infra/types/common';
import type { TOptional } from '@/types/common';

// Argument "ttl" in seconds

export type TFetchWithTTL = {
  apolloClient: ApolloClient<NormalizedCacheObject>;
  fetchOptions?: TOptional<unknown>;
  fetchPolicy?: TOptional<FetchPolicy>;
};

export type TFetchedItem = { __fetchedAt?: TOptional<number> };
export type TTimestampable = TFetchedItem | TFetchedItem[];
export type TArgsWithTTL<A extends TFetchWithTTL> = { ttl: number } & Omit<A, keyof TFetchWithTTL>;

const fetchWithTTLSupport =
  <A extends TFetchWithTTL, R extends TTimestampable>(
    fetcher: (args: A) => Promise<TStoreLoadablePartition<R>>,
    apolloClient: ApolloClient<NormalizedCacheObject>,
  ) =>
  async ({ ttl, ...args }: TArgsWithTTL<A>): Promise<TStoreLoadablePartition<R>> => {
    const fetchArgs = { ...(args as unknown as A), apolloClient, fetchOptions: { cache: 'no-store' } };
    const result = await fetcher(fetchArgs);
    const { data, error } = result || {};
    if (!result || error) return result;

    const lastFetchTime = extractLastFetchTime(data);
    if (lastFetchTime && Date.now() < lastFetchTime + 1000 * ttl) return result;

    // data?.forEach(makeCacheInvalidator(apolloClient.cache));
    await apolloClient.clearStore();
    return await fetcher({ ...fetchArgs, fetchPolicy: 'network-only' });
  };

export const extractLastFetchTime = (items: TTimestampable): TOptional<number> => {
  if (Array.isArray(items)) {
    const times = items.map((item) => item?.__fetchedAt).filter(Boolean) as number[];
    return times.length ? Math.min(...times) : undefined;
  }
  return items?.__fetchedAt;
};

export const makeCacheInvalidator =
  <C = unknown>(apolloCache: ApolloCache<C>) =>
  (item: StoreObject) => {
    apolloCache.modify({
      fields(fieldValue, details) {
        return details.INVALIDATE;
      },
      id: apolloCache.identify(item),
    });
  };

export default fetchWithTTLSupport;
