import { extractClientIp } from '@/helpers/ssr/headers/handlers';

import withHeaders from '../helpers/withHeaders';
import { FetchablePartName, type TFetchablePart } from '../types';

const clientIp: TFetchablePart<FetchablePartName.CLIENT_IP> = {
  // dependOn: [],
  fetcher: () => Promise.resolve(withHeaders(extractClientIp)!),
  name: FetchablePartName.CLIENT_IP,
};

export default clientIp;
