import { CURRENCY_CODE_COOKIE } from '@/constants/settings';
import { ACCESS_KEY_PARAM } from '@/ducks/accessKeys/constants';
import decodeAKFromUrlParam from '@/ducks/accessKeys/decodeAKFromUrlParam';
import { selectSupportedCurrenciesList } from '@/ducks/common/lookup/selectors';
import { selectCurrencyCode } from '@/ducks/filters/selectors';
import { selectAgencyCurrencies } from '@/ducks/fm/selectors';
import {
  buildFetchVoyageListDataPayload,
  makeVoyagesApiDatesOptions,
} from '@/ducks/pages/chooseVoyage/actions/fetchPackages';
import { CelebrationSearchParams } from '@/ducks/programs/celebrations/searchParams';
import { env } from '@/environment';
import { fetchVoyages, type TFetchVoyagesPayload } from '@/features/api/endpoints/voyage';
import checkFlowType from '@/features/flowType/checkFlowType';
import sharedCall from '@/helpers/sharedCall';
import { extractSearchParams } from '@/helpers/ssr/headers/handlers';
import validateCurrencyCode from '@/helpers/validateCurrencyCode';
import { type TVoyagesResponse } from '@/infra/types/voyageInfo/package';

import buildInitialState from '../../buildInitialState';
import { defaultComparator } from '../comparators';
import withHeaders from '../helpers/withHeaders';
import { FetchablePartName, type TFetchableArgs, type TFetchablePart, type TFetchableRawArgs } from '../types';

const EMPTY_RESPONSE = {
  defaultGenericCategoryCodes: [],
  defaultPackages: [],
  genericCategoryCodes: [],
  packages: [],
} as TVoyagesResponse;

type TArgs = { payload: TFetchVoyagesPayload };

const voyages: TFetchablePart<FetchablePartName.VOYAGES, TArgs> = {
  cookieNames: [CURRENCY_CODE_COOKIE],
  dependOn: (args) => {
    const { urlParams } = args || {};
    return [
      FetchablePartName.AGENT_AGENCY_DETAILS,
      FetchablePartName.LOOKUP,
      FetchablePartName.MNVV_DATA,
      FetchablePartName.SETTINGS,
      FetchablePartName.VOYAGE_DATA,
      ...(urlParams && checkFlowType(urlParams!).isCelebration ? [FetchablePartName.SAILINGS] : []),
    ];
  },
  fetcher: sharedCall(
    async ({ onResponseHeaders, payload }: TFetchableArgs<TArgs>) => {
      const options = { next: { revalidate: +env.NEXT_CACHE_TIME } };
      const response = await fetchVoyages({ ...options, onResponseHeaders, payload });
      return { apiPayload: payload, response: response || EMPTY_RESPONSE };
    },
    { compareFn: defaultComparator },
  ),
  name: FetchablePartName.VOYAGES,
  prepareArgs: ({ cookies, dependencies, urlParams }: TFetchableRawArgs): TArgs => {
    const accessKey = decodeAKFromUrlParam(urlParams?.[ACCESS_KEY_PARAM] as string);
    const initialState = buildInitialState(dependencies!, withHeaders(extractSearchParams));
    const state = accessKey
      ? {
          ...initialState,
          accessKeys: { ...initialState.accessKeys, accessKey, promoCode: accessKey, state: 'DETAIL' as const },
        }
      : initialState;
    const availableCurrencies = selectAgencyCurrencies(initialState) ?? selectSupportedCurrenciesList(initialState);
    const currencyCode =
      validateCurrencyCode(urlParams?.currencyCode as string, availableCurrencies) ||
      validateCurrencyCode(cookies?.[CURRENCY_CODE_COOKIE], availableCurrencies) ||
      selectCurrencyCode(initialState);
    const { debarkDate, embarkDate } = dependencies?.sailings || {};
    const options = embarkDate && debarkDate ? makeVoyagesApiDatesOptions(embarkDate, debarkDate) : undefined;
    const payload = buildFetchVoyageListDataPayload(state, { ...options, currencyCode });
    return { payload };
  },
  urlParamNames: [
    ACCESS_KEY_PARAM,
    CelebrationSearchParams.referralCode,
    CelebrationSearchParams.referralType,
    'currencyCode',
  ],
};

export default voyages;
