import { MNVVSearchParams } from '@/ducks/mnvv/constants';
import { type MnvvData } from '@/ducks/mnvv/types';
import { env } from '@/environment';
import checkFlowType from '@/features/flowType/checkFlowType';
import { FlowType } from '@/features/flowType/types';
import { fetchMnvvData, type TFetchMnvvArgs } from '@/helpers/api/resources';
import sharedCall from '@/helpers/sharedCall';

import { defaultComparator } from '../comparators';
import { FetchablePartName, type TFetchableArgs, type TFetchablePart, type TFetchableRawArgs } from '../types';

type TArgs = TFetchMnvvArgs & {
  channelID?: string;
  isMNVV?: boolean;
};

const mnvvData: TFetchablePart<FetchablePartName.MNVV_DATA, TArgs> = {
  cookieNames: ['token'],
  // dependOn: [],
  fetcher: sharedCall(
    async ({ channelID, isMNVV, onResponseHeaders, reservationNumber, userToken }: TFetchableArgs<TArgs>) => {
      if (isMNVV && userToken) {
        const options = { next: { revalidate: +env.NEXT_CACHE_TIME }, onResponseHeaders };
        const response = await fetchMnvvData({ ...options, reservationNumber, userToken });
        return { ...response, channelID: channelID! } as MnvvData;
      }
      return {} as MnvvData;
    },
    { compareFn: defaultComparator },
  ),
  name: FetchablePartName.MNVV_DATA,
  prepareArgs: ({ cookies, urlParams }: TFetchableRawArgs): TArgs => {
    const { token } = cookies || {};
    const { flowParams, isMNVV } = checkFlowType(urlParams!);
    const { channelID, reservationNumber } = flowParams[FlowType.MNVV] || {};
    return { channelID, isMNVV, reservationNumber, userToken: token };
  },
  urlParamNames: [
    MNVVSearchParams.agencyId,
    MNVVSearchParams.agentId,
    MNVVSearchParams.channelID,
    MNVVSearchParams.reservationNumber,
  ],
};

export default mnvvData;
