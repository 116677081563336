import type { RootState } from '@/store';

import { selectConfigServiceFeatureCelebrations } from '@/ducks/common/settings';
import { env } from '@/environment';
import { fetchCampaignAndLegal } from '@/helpers/api/resources';
import sharedCall from '@/helpers/sharedCall';

import { defaultComparator } from '../comparators';
import { FetchablePartName, type TFetchableArgs, type TFetchablePart, type TFetchableRawArgs } from '../types';

type TArgs = { areCelebrationsEnabled: boolean };

const campaignAndLegal: TFetchablePart<FetchablePartName.CAMPAIGN_AND_LEGAL, TArgs> = {
  dependOn: [FetchablePartName.SETTINGS],
  fetcher: sharedCall(
    ({ areCelebrationsEnabled, onResponseHeaders }: TFetchableArgs<TArgs>) => {
      const options = { next: { revalidate: +env.NEXT_CACHE_TIME }, onResponseHeaders };
      return fetchCampaignAndLegal(areCelebrationsEnabled, options);
    },
    { compareFn: defaultComparator },
  ),
  name: FetchablePartName.CAMPAIGN_AND_LEGAL,
  prepareArgs: ({ dependencies }: TFetchableRawArgs): TArgs => {
    const { settings } = dependencies || {};
    const initialState = { common: { settings } } as RootState;
    const areCelebrationsEnabled = selectConfigServiceFeatureCelebrations(initialState) ?? true;
    return { areCelebrationsEnabled };
  },
};

export default campaignAndLegal;
