import isEqual from 'lodash/isEqual';

import { type TFetchableArgs } from './types';

type TArgs = [TFetchableArgs];

export const defaultComparator = (args1: TArgs, args2: TArgs): boolean => {
  const { onResponseHeaders: _1, ...rest1 } = args1?.[0] || {};
  const { onResponseHeaders: _2, ...rest2 } = args2?.[0] || {};
  return isEqual(rest1, rest2);
};

export const emptyComparator = (_1: TArgs, _2: TArgs): boolean => true;
