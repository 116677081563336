import { env } from '@/environment';
import { fetchLookup } from '@/features/api/endpoints/common';
import sharedCall from '@/helpers/sharedCall';

import { emptyComparator } from '../comparators';
import { FetchablePartName, type TFetchableArgs, type TFetchablePart } from '../types';

// This part does not directly depend on the client info, but uses it only together with it

const lookup: TFetchablePart<FetchablePartName.LOOKUP> = {
  // dependOn: [],
  fetcher: sharedCall(
    ({ onResponseHeaders }: TFetchableArgs) => {
      const options = { next: { revalidate: +env.NEXT_CACHE_TIME }, onResponseHeaders };
      return fetchLookup(options);
    },
    { compareFn: emptyComparator },
  ),
  name: FetchablePartName.LOOKUP,
};

export default lookup;
