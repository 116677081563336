import { extractCountryCode, extractSubRegionCode } from '@/helpers/ssr/headers/handlers';

import withHeaders from '../helpers/withHeaders';
import { FetchablePartName, type TFetchablePart } from '../types';

const geoLocation: TFetchablePart<FetchablePartName.GEO_LOCATION> = {
  fetcher: () =>
    Promise.resolve({
      countryCode: withHeaders(extractCountryCode),
      stateCode: withHeaders(extractSubRegionCode),
    }),
  name: FetchablePartName.GEO_LOCATION,
};

export default geoLocation;
