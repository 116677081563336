import { gql } from '@apollo/client';

import type { Voyage } from '@/infra/types/voyageInfo/voyage';

export type FetchVoyagesResponse = {
  myVoyagesV2: Voyage[];
};

export const FETCH_VOYAGES = gql`
  query Voyages($reservationNumbers: [String!]!) {
    myVoyagesV2(reservationNumbers: $reservationNumbers) {
      reservationNumber
      reservationStatus
      backgroundImageUrl {
        src
        alt
      }
      cabins {
        isVip
        name
      }
      hoursToGo
      debarkDate
      embarkDate
      isTourOperator
      packageCode
      packageName
      sailorCount
      shipCode
      shipName
      voyageId
      isMNVV
      currencyCode
      bookedDate
      agencyId
      agentId
      paidVia
    }
  }
`;
