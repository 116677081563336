import { type ReadonlyURLSearchParams } from 'next/navigation';

import { FMSearchParams } from '@/ducks/fm/types';
import { MNVVSearchParams } from '@/ducks/mnvv/constants';
import { makeSearchParamGetter } from '@/ducks/mnvv/utils';
import { CelebrationSearchParams } from '@/ducks/programs/celebrations/searchParams';
import { type TUrlParams } from '@/infra/types/common';
import { type TOptional } from '@/types/common';

import { FlowType } from './types';

export type TFlowParams = Record<FlowType, Record<string, TOptional<string>>>;

export type TCheckFlowType = {
  flowParams: TFlowParams;
  isCelebration?: boolean;
  isFM?: boolean;
  isMNVV?: boolean;
};

const checkFlowType = (searchParams: URLSearchParams | ReadonlyURLSearchParams | TUrlParams): TCheckFlowType => {
  const getParam = makeSearchParamGetter(searchParams);
  const agencyId = getParam(FMSearchParams.agencyId);
  const agentId = getParam(FMSearchParams.agentId);
  const channelID = getParam(MNVVSearchParams.channelID);
  const reservationNumber = getParam(MNVVSearchParams.reservationNumber);
  const referralType = getParam(CelebrationSearchParams.referralType);
  const referralCode = getParam(CelebrationSearchParams.referralCode);

  const hasMNVVPart = !!(channelID && reservationNumber);
  const isFM = !!(agencyId && agentId) && !hasMNVVPart;
  const isMNVV = !!(agencyId || agentId) && hasMNVVPart;
  const isCelebration = !!(referralType && referralCode);

  const flowParams = {} as TFlowParams;
  if (isFM) {
    const bookingChannel = getParam(FMSearchParams.bookingChannel);
    flowParams[FlowType.FM] = { agencyId, agentId, bookingChannel };
  }
  if (isMNVV) {
    const bookedDate = getParam(MNVVSearchParams.bookedDate);
    const maxDate = getParam(MNVVSearchParams.maxDate);
    flowParams[FlowType.MNVV] = { agencyId, agentId, bookedDate, channelID, maxDate, reservationNumber };
  }
  if (isCelebration) {
    flowParams[FlowType.CELEBRATION] = { referralCode, referralType };
  }

  return { flowParams, isCelebration, isFM, isMNVV };
};

export default checkFlowType;
