import { CURRENCY_CODE_COOKIE } from '@/constants/settings';
import { AGENCY_COOKIE, AGENCY_CURRENCY_COOKIE, AGENT_COOKIE } from '@/ducks/fm/constants';
import { FMSearchParams } from '@/ducks/fm/types';
import { MNVVSearchParams } from '@/ducks/mnvv/constants';
import { fetchAgentDetails, type TFetchAgentDetailsArgs } from '@/helpers/api/resources';
import sharedCall from '@/helpers/sharedCall';

import { defaultComparator } from '../comparators';
import { FetchablePartName, type TFetchableArgs, type TFetchablePart, type TFetchableRawArgs } from '../types';

type TArgs = Omit<TFetchAgentDetailsArgs, 'onResponseHeaders'>;

const agentAgencyDetails: TFetchablePart<FetchablePartName.AGENT_AGENCY_DETAILS, TArgs> = {
  cookieNames: [AGENCY_COOKIE, AGENT_COOKIE, AGENCY_CURRENCY_COOKIE, CURRENCY_CODE_COOKIE],
  dependOn: [FetchablePartName.CLIENT_INFO],
  fetcher: sharedCall(
    ({ cookies, defaultCurrencyCode, onResponseHeaders, urlParams }: TFetchableArgs<TArgs>) => {
      return fetchAgentDetails({ cookies, defaultCurrencyCode, onResponseHeaders, urlParams });
    },
    { compareFn: defaultComparator },
  ),
  name: FetchablePartName.AGENT_AGENCY_DETAILS,
  prepareArgs: ({ cookies, dependencies, urlParams }: TFetchableRawArgs): TArgs => {
    const { defaultCurrencyCode } = dependencies?.clientInfo?.currenciesData || {};
    return { cookies: cookies!, defaultCurrencyCode, urlParams: urlParams! };
  },
  urlParamNames: [
    FMSearchParams.agencyId,
    FMSearchParams.agentId,
    FMSearchParams.bookingChannel,
    MNVVSearchParams.channelID,
    MNVVSearchParams.reservationNumber,
    'currencyCode',
  ],
};

export default agentAgencyDetails;
