import pick from 'lodash/pick';

import { selectCurrencyCode, selectSailors } from '@/ducks/filters/selectors';
import { fetchQuickSearchItemsData, QUICK_SEARCH_RESOURCES, type TFetchQuickSearchArgs } from '@/helpers/api/resources';
import sharedCall from '@/helpers/sharedCall';
import { extractSearchParams } from '@/helpers/ssr/headers/handlers';

import buildInitialState from '../../buildInitialState';
import { defaultComparator } from '../comparators';
import withHeaders from '../helpers/withHeaders';
import { FetchablePartName, type TFetchableArgs, type TFetchablePart, type TFetchableRawArgs } from '../types';

type TArgs = Omit<TFetchQuickSearchArgs, 'onResponseHeaders'>;

const quickSearch: TFetchablePart<FetchablePartName.QUICK_SEARCH, TArgs> = {
  dependOn: [
    FetchablePartName.AGENT_AGENCY_DETAILS,
    FetchablePartName.LOOKUP,
    FetchablePartName.MNVV_DATA,
    FetchablePartName.RESOURCES,
    FetchablePartName.SETTINGS,
  ],
  fetcher: sharedCall(
    ({ currencyCode, onResponseHeaders, resources, sailors }: TFetchableArgs<TArgs>) => {
      return fetchQuickSearchItemsData({ currencyCode, onResponseHeaders, resources, sailors });
    },
    { compareFn: defaultComparator },
  ),
  name: FetchablePartName.QUICK_SEARCH,
  prepareArgs: ({ dependencies }: TFetchableRawArgs): TArgs => {
    type TRes = TFetchQuickSearchArgs['resources'];
    const resources = pick(dependencies?.resources as unknown as TRes, ...QUICK_SEARCH_RESOURCES);
    const initialState = buildInitialState(dependencies!, withHeaders(extractSearchParams));
    const currencyCode = selectCurrencyCode(initialState);
    const sailors = selectSailors(initialState);
    return { currencyCode, resources, sailors };
  },
};

export default quickSearch;
