import geoLocation from '@/store/fetchableParts/parts/geoLocation';

import { FetchablePartName } from '../types';
import agentAgencyDetails from './agentAgencyDetails';
import campaignAndLegal from './campaignAndLegal';
import clientInfo from './clientInfo';
import clientIp from './clientIp';
import lookup from './lookup';
import mnvvData from './mnvvData';
import promoBanners from './promoBanners';
import quickSearch from './quickSearch';
import resources from './resources';
import sailings from './sailings';
import settings from './settings';
import strikethrough from './strikethrough';
import travelInsurance from './travelInsurance';
import voyageData from './voyageData';
import voyages from './voyages';

const modules = {
  [FetchablePartName.AGENT_AGENCY_DETAILS]: agentAgencyDetails,
  [FetchablePartName.CAMPAIGN_AND_LEGAL]: campaignAndLegal,
  [FetchablePartName.CLIENT_INFO]: clientInfo,
  [FetchablePartName.CLIENT_IP]: clientIp,
  [FetchablePartName.GEO_LOCATION]: geoLocation,
  [FetchablePartName.LOOKUP]: lookup,
  [FetchablePartName.MNVV_DATA]: mnvvData,
  [FetchablePartName.PROMO_BANNERS]: promoBanners,
  [FetchablePartName.QUICK_SEARCH]: quickSearch,
  [FetchablePartName.RESOURCES]: resources,
  [FetchablePartName.SAILINGS]: sailings,
  [FetchablePartName.SETTINGS]: settings,
  [FetchablePartName.STRIKETHROUGH]: strikethrough,
  [FetchablePartName.TRAVEL_INSURANCE]: travelInsurance,
  [FetchablePartName.VOYAGE_DATA]: voyageData,
  [FetchablePartName.VOYAGES]: voyages,
};

export default modules;
