import isEqual from 'lodash/isEqual';

import { cookies } from '@/ducks/cookies';
import { getURLSearchParams } from '@/ducks/routes/history';
import searchParamsAsObject from '@/helpers/url/searchParamsAsObject';
import { FetchablePartName, type TFetchableData, type TFetchableResult } from '@/store/fetchableParts/types';
import { type TOptional } from '@/types/common';

import fetchableParts from '../fetchableParts/parts';
import { type TPrefillInfo, type TPrefillItem } from './stuff';

type TNamesMap = { [key in FetchablePartName]?: string[] };

export const makeParamNamesMaps = () => {
  const prepare = (list: TOptional<string[]>, exclude: string) => {
    if (list?.length) {
      const result = list.filter((item) => item !== exclude);
      return { excluded: result.length !== list.length, list: result.length ? result : undefined };
    }
  };
  const urlParamNames = {} as TNamesMap;
  const cookieNames = {} as TNamesMap;
  const crossNames = {} as TNamesMap;
  Object.keys(fetchableParts).forEach((key) => {
    const name = key as FetchablePartName;
    const urlParams = prepare(fetchableParts[name].urlParamNames, 'currencyCode');
    const cookies = prepare(fetchableParts[name].cookieNames, 'currencyCode');
    if (urlParams?.list) urlParamNames[name] = urlParams.list;
    if (cookies?.list) cookieNames[name] = cookies.list;
    if (urlParams?.excluded || cookies?.excluded) crossNames[name] = ['currencyCode'];
  });
  return { cookieNames, crossNames, urlParamNames };
};

const maps = makeParamNamesMaps();

type TValues = TOptional<Record<string, unknown>>;

export const hasEqualValues = (
  keys: TOptional<string[]>,
  sequence1: TValues[],
  sequence2: TValues[],
): TOptional<boolean> => {
  const selectValue = (sequence: TValues[], key: string): unknown => {
    for (const values of sequence) {
      if (values) {
        const value = values[key];
        if (value !== undefined && value !== null) return value;
      }
    }
  };
  return !keys?.length || keys.every((key) => selectValue(sequence1, key) === selectValue(sequence2, key));
};

export const compareVersions = (v1: string, v2: string): number => {
  const t1 = Date.parse(v1);
  const t2 = Date.parse(v2);
  if (isNaN(t1) && isNaN(t2)) return 1;
  return (t1 || 0) - (t2 || 0);
};

export type TChooseRelevant = {
  relevant: TFetchableData;
  versions: TPrefillInfo;
};

const chooseRelevant = (
  fetched: TFetchableResult,
  previousVersions?: TOptional<TPrefillInfo>,
  defaultParams?: Record<string, unknown>,
): TOptional<TChooseRelevant> => {
  const versions = {} as TPrefillInfo;
  const allUrlParams = searchParamsAsObject(getURLSearchParams()) ?? {};
  const allCookies = cookies.getAll();
  const relevant = (fetched ? Object.keys(fetched) : []).reduce<TFetchableData>((acc, key) => {
    const name = key as keyof typeof fetched;
    const { args, cookies, data, error, urlParams, version } = fetched[name]!;
    const prev = previousVersions?.[name] || ({} as TPrefillItem);
    const urlParamNames = maps.urlParamNames[name];
    const cookieNames = maps.cookieNames[name];
    const crossNames = maps.crossNames[name];
    if (
      !prev.version ||
      (hasEqualValues(urlParamNames, [allUrlParams, defaultParams], [urlParams, defaultParams]) &&
        hasEqualValues(cookieNames, [allCookies], [cookies]) &&
        hasEqualValues(crossNames, [allUrlParams, allCookies, defaultParams], [urlParams, cookies, defaultParams]) &&
        (!isEqual(args, prev.args) || (version !== prev.version && compareVersions(version, prev.version) > 0)))
    ) {
      versions[name] = {
        ...(args ? { args } : undefined),
        ...(cookies ? { cookies } : undefined),
        ...(urlParams ? { urlParams } : undefined),
        version,
      };
      return { ...acc, [name]: data ?? error };
    }
    return acc;
  }, {} as TFetchableData);
  if (Object.keys(relevant).length) return { relevant, versions };
};

export default chooseRelevant;
