import { env } from '@/environment';
import { fetchSettings } from '@/features/api/endpoints/common';
import sharedCall from '@/helpers/sharedCall';

import { emptyComparator } from '../comparators';
import { FetchablePartName, type TFetchableArgs, type TFetchablePart } from '../types';

const settings: TFetchablePart<FetchablePartName.SETTINGS> = {
  // dependOn: [],
  fetcher: sharedCall(
    ({ onResponseHeaders }: TFetchableArgs) => {
      const options = { next: { revalidate: +env.NEXT_CACHE_TIME }, onResponseHeaders };
      return fetchSettings(options);
    },
    { compareFn: emptyComparator },
  ),
  name: FetchablePartName.SETTINGS,
};

export default settings;
