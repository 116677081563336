'use client';

import { ApolloLink } from '@apollo/client';
import {
  NextSSRApolloClient,
  NextSSRInMemoryCache,
  SSRMultipartLink,
} from '@apollo/experimental-nextjs-app-support/ssr';

import { env } from '@/environment';
import makeApolloLink from '@/features/apollo/makeApolloLink';
import { getIsProdBuild, isProdEnv } from '@/helpers/util/env';

export const makeSSRClient = () => {
  const fetchOptions = { next: { revalidate: +env.NEXT_CACHE_TIME } };
  const link = makeApolloLink({ fetchOptions });

  return new NextSSRApolloClient({
    cache: new NextSSRInMemoryCache({
      typePolicies: {
        Lookup: {
          keyFields: [],
        },
        Settings: {
          keyFields: [],
        },
        Startup: {
          keyFields: [],
        },
        Voyagedata: {
          keyFields: [],
        },
      },
    }),
    connectToDevTools: !isProdEnv() || !getIsProdBuild(),
    link:
      typeof window === 'undefined'
        ? ApolloLink.from([
            // in a SSR environment, if you use multipart features like
            // @defer, you need to decide how to handle these.
            // This strips all interfaces with a `@defer` directive from your queries.
            new SSRMultipartLink({
              stripDefer: true,
            }),
            link,
          ])
        : link,
  });
};
