import { type TOptional } from '@/types/common';

/* eslint-disable-next-line @typescript-eslint/no-unused-vars */
const parseJsonOrReturn = <R = unknown>(value: string): R | string => tryParseJson<R>(value) ?? value;

const tryParseJson = <R = unknown>(value: string, defaultValue?: R): TOptional<R> => {
  try {
    return JSON.parse(value) as R;
  } catch {
    if (defaultValue !== undefined) return defaultValue;
    // It's impossible to return the R type. Do nothing
  }
};

export default tryParseJson;
