import type { TAgencyAgentDetails } from '@/infra/types/fmlink/agency';
import type { TAgencyAgentResponse } from '@/infra/types/fmlink/agent';

export const buildAgentDetails = (details: TAgencyAgentDetails): TAgencyAgentResponse => {
  const { agency, agent, bookingChannel, defaultCurrency: currency, valid: isValid } = details;
  const multiCurrencies = details?.additionalCurrencies?.map((currency) => currency.code);
  const {
    agentId,
    agentName,
    agentPhone: { code, phoneNumber },
    email,
  } = agent;
  const { agencyId, agencyName } = agency;
  const agentData = {
    agencyCurrencyCode: currency,
    agencyDetails: {
      agencyId,
      agencyName,
      multiCurrencies,
    },
    agentDetails: {
      agentId,
      agentName,
      bookingChannel,
      code,
      currency,
      email,
      isValid,
      phoneNumber,
      source: 'book',
    },
  };
  return agentData;
};
