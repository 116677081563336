import type { GeoLocation } from '@/ducks/common/types';

import { type LookupSlice } from '@/ducks/common/lookup/types';
import { type ResourcesSlice } from '@/ducks/common/resources';
import { type SettingsSlice } from '@/ducks/common/settings';
import { type MnvvData } from '@/ducks/mnvv/types';
import { type TFetchVoyagesSuccessResult } from '@/ducks/pages/chooseVoyage/slice';
import { type QuickSearchItemsData } from '@/features/promo/QuickSearchCards/model/types';
import { type TStorePromoBanners } from '@/features/promoBanners/slice';
import { type TStoreStrikethrough } from '@/features/strikethrough/slice';
import { type GQLClientInfoLookup } from '@/helpers/api/graphql';
import { type TCampaignAndLegal, type TFetchAgentDetails } from '@/helpers/api/resources';
import { type TWithResponseHeaders } from '@/infra/types/api';
import { type TReceived, type TUrlParams } from '@/infra/types/common/base';
import { type TTravelInsuranceResponse } from '@/infra/types/summary';
import { type VoyageDataResponse } from '@/infra/types/voyageInfo/package';
import { type TSailingDataResponse } from '@/infra/types/voyageInfo/sailing';
import { type TOptional } from '@/types/common';

export type TFetched<D> = Partial<TReceived<D, Error>> & {
  args?: Record<string, unknown>;
  cookies?: Record<string, unknown>;
  urlParams?: Record<string, unknown>;
  version: string;
};

export const enum FetchablePartName {
  AGENT_AGENCY_DETAILS = 'agentAgencyDetails',
  CAMPAIGN_AND_LEGAL = 'campaignAndLegal',
  CLIENT_INFO = 'clientInfo',
  CLIENT_IP = 'clientIp',
  GEO_LOCATION = 'geoLocation',
  LOOKUP = 'lookup',
  MNVV_DATA = 'mnvvData',
  PROMO_BANNERS = 'promoBanners',
  QUICK_SEARCH = 'quickSearch',
  RESOURCES = 'resources',
  SAILINGS = 'sailings',
  SETTINGS = 'settings',
  STRIKETHROUGH = 'strikethrough',
  TRAVEL_INSURANCE = 'travelInsurance',
  VOYAGE_DATA = 'voyageData',
  VOYAGES = 'voyages',
}

export type TFetchablePartValues = {
  [FetchablePartName.AGENT_AGENCY_DETAILS]: TOptional<TFetchAgentDetails>;
  [FetchablePartName.CAMPAIGN_AND_LEGAL]: TCampaignAndLegal;
  [FetchablePartName.CLIENT_INFO]: TOptional<GQLClientInfoLookup>;
  [FetchablePartName.CLIENT_IP]: string;
  [FetchablePartName.GEO_LOCATION]: GeoLocation;
  [FetchablePartName.LOOKUP]: LookupSlice;
  [FetchablePartName.MNVV_DATA]: TOptional<MnvvData>;
  [FetchablePartName.PROMO_BANNERS]: TStorePromoBanners;
  [FetchablePartName.QUICK_SEARCH]: QuickSearchItemsData;
  [FetchablePartName.RESOURCES]: ResourcesSlice;
  [FetchablePartName.SAILINGS]: TSailingDataResponse;
  [FetchablePartName.SETTINGS]: SettingsSlice;
  [FetchablePartName.STRIKETHROUGH]: TStoreStrikethrough;
  [FetchablePartName.TRAVEL_INSURANCE]: TTravelInsuranceResponse;
  [FetchablePartName.VOYAGE_DATA]: VoyageDataResponse;
  [FetchablePartName.VOYAGES]: TFetchVoyagesSuccessResult;
};

export type TFetchableData = Partial<TFetchablePartValues>;

export type TFetchableResult = { [N in FetchablePartName]: TFetched<TFetchablePartValues[N]> };

export type TCookies = Record<string, string>;

export type TOuterParams = {
  cookies?: TCookies;
  urlParams?: TUrlParams;
};

export type TFetchableRawArgs = TOuterParams & {
  dependencies?: TFetchableData;
};

export type TFetchableArgs<A extends Record<string, unknown> = Record<string, unknown>> = A & TWithResponseHeaders;

export type TFetchablePart<N extends FetchablePartName, A extends Record<string, unknown> = Record<string, unknown>> = {
  cookieNames?: string[];
  dependOn?: FetchablePartName[] | ((args: TOuterParams) => TOptional<FetchablePartName[]>);
  fetcher: (args: TFetchableArgs<A>) => Promise<TFetchablePartValues[N]>;
  name: N;
  prepareArgs?: (args: TFetchableRawArgs) => A;
  urlParamNames?: string[];
};
